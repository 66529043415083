
import { defineComponent, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import { useQuestions, useDeleteQuestion } from '@/composables/api';
import { QuestionIdOptions } from '@/services/api';
import SearchBox from '@/components/keyword-searching/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { useI18n } from 'vue-i18n';


export default defineComponent({
  components: {
    SearchBox,
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const keyword = ref();

    const sortBy = ref();
    const descSortBy = ref('updated_at');
    const { t } = useI18n();


    const { data, isLoading, isFetching, refetch } = useQuestions({ page, keyword, sortBy, descSortBy });
    const { isLoading: isDeletedLoading, mutate } = useDeleteQuestion();

    const statusIndex = ref(undefined);

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const deleteQuestion = ({ questionId }: QuestionIdOptions, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm(
        'Are you sure you want to delete?',
        'Warning',
        {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      )
        .then(() => {
          mutate({ questionId }, {
            onSuccess() {
              refetch.value();

              ElMessage({
                type: 'success',
                message: 'Delete successfully'
              });
            },
            onError(error: any) {
              ElMessage({
                message: error.response?.data.message,
                type: 'error'
              });
            }
          });
        })
        .catch();
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      statusIndex,
      isDeletedLoading,
      sortChange,
      searchKeyword,
      t,
      deleteQuestion
    };
  }
});
